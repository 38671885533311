@import url('https://fonts.googleapis.com/css?family=Raleway:400,600,700&display=swap&subset=latin-ext');

@font-face {
  font-family: 'Gilroy-Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Gilroy-Bold'), url('../fonts/Gilroy-Bold/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  font-style: normal;
  font-weight: 700;
  src: local('Gilroy-ExtraBold'), url('../fonts/Gilroy-Bold/Gilroy-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-SemiBold'), url('../fonts/Gilroy-SemiBold/Gilroy-SemiBold.ttf') format('truetype');
}

/* HTML reset */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:18px;
    vertical-align:baseline;
    background:transparent;
}

body {
    line-height:1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
}

nav ul {
    list-style:none;
}

blockquote, q {
    quotes:none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content:'';
    content:none;
}

a {
    margin:0;
    padding:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
    text-decoration: none;
}

/* change colours to suit your needs */
ins {
    background-color:#ff9;
    color:#000;
    text-decoration:none;
}

/* change colours to suit your needs */
mark {
    background-color:#ff9;
    color:#000;
    font-style:italic;
    font-weight:bold;
}

del {
    text-decoration: line-through;
}

abbr[title], dfn[title] {
    border-bottom:1px dotted;
    cursor:help;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}

/* change border colour to suit your needs */
hr {
    display:block;
    height:1px;
    border:0;
    border-top:1px solid #cccccc;
    margin:1em 0;
    padding:0;
}

input, select {
    vertical-align:middle;
}

p {
  font-weight: 600;
  line-height: 30px;
  color: rgba(26, 42, 64, 0.7);
  white-space: pre-wrap;
}

html, body {
  font-family: 'Raleway', sans-serif;

  /* Dev purpose - to see menu */
  background: #000528;
}

h1 {
  font-family: "Gilroy-Bold";
  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
  margin-bottom: 30px;
  white-space: pre-wrap;
}

h2 {
  font-family: "Gilroy-Bold";
  font-size: 56px;
  font-weight: 700;
  line-height: 72px;
  white-space: pre-wrap;
}

h3 {
  font-family: "Gilroy-ExtraBold";
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  white-space: pre-wrap;
}

h3 {
  font-family: "Gilroy-SemiBold";
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  white-space: pre-wrap;
}

@media only screen and (max-width: 1024px){
  h1{
    font-size: 45px;
    line-height: 45px;
  }
  h2{
    font-size: 36px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 900px){
  h1{
    margin-bottom: 35px;
  }
}
@media only screen and (max-width: 800px){
  h1{
    font-size: 34px;
    line-height: normal;
  }
  h2{
    font-size: 28px;
    line-height: normal;
  }
}

img{
  max-width: 100%;
}
.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb70 {
  margin-bottom: 70px !important;
}

.videoBoxShadow {
  box-shadow: 0 8px 21px 0 rgba(0,0,0,.5);
}

.subheading {
  margin-top: 20px;
  max-width: 60%;
}

.purpleButton {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background-color: #7b61d6;
  color: #FFF;
  padding: 20px 70px;
  transition: ease-in-out 0.5s;
}
.purpleButton:hover{
  background-color: #000528;
}

@media only screen and (max-width: 500px){
  .anchorBlock{
    display: block;
    margin-top: 20px!important;
  }
}

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #FFF;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #FFF;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -10px;
}
.hamburger-inner::after {
  bottom: -10px;
}
.hamburger--spring .hamburger-inner {
  top: 2px;
  transition: background-color 0s 0.13s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 10px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.social a {
  opacity: 1 !important;
}

.cookieConsent {
  background-color: rgba(0,0,0,.85) !important;
}

.cookieConsent button {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 6px !important;
  background-color: rgb(51, 53, 95) !important;
  color: rgb(255, 255, 255) !important;
  border-radius: 6px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: rgb(163, 137, 254) !important;
  border-image: initial !important;
  transition: all 0.5s ease-in-out 0s !important;
}
